/**
 * File ml-switcher-controller.js.
 * Control the display of multi-language options.
 */
(function(){

	'use strict';

	//
	// VARIABLES
	//
	var _mlSwitcherActiveClass = 'ml-switcher-active',

		_mlOptionsWrapper = '.ml-switcher-wrapper',
		_mlSwitcherSelector = '[data-ml-switcher]',

		_mlSwitcherActiveSelector = '.' + _mlSwitcherActiveClass;



	/**
	 * Toggle the visibility of the closest switcher
	 */
	var toggleSwitcherDisplay = function( event ) {
		var switcherToggler = event.target.closest( _mlSwitcherSelector );

		// Bail if switcher toggler not available
		if ( ! switcherToggler ) { return; }

		var switcher = switcherToggler.parentNode;

		// Bail if switcher not available
		if ( ! switcher ) { return; }

		switcher.classList.toggle( _mlSwitcherActiveClass );
	}



	/**
	 * Toggle the visibility of the closest switcher
	 */
	var hideSwitchers = function() {
		var switchers = document.querySelectorAll( _mlSwitcherActiveSelector );

		for ( var i = 0; i < switchers.length; i++) {
			switchers[i].classList.remove( _mlSwitcherActiveClass );
		}
	}



	/**
	 * Handle captured click
	 */
	var handleCapturedClick = function( event ) {		
		if ( event.target.closest( _mlSwitcherSelector ) ) {
			event.preventDefault();
			
			// Hide other active swichers
			var activeSwitcher = document.querySelector( _mlSwitcherActiveSelector );
			if ( activeSwitcher != event.target.closest( _mlSwitcherActiveSelector ) ) {
				hideSwitchers();
			}

			toggleSwitcherDisplay( event );
		}
		else {
			hideSwitchers();
		}
	};



	/**
	 * Initialize the product details controller script
	 */
	var init = function() {
		// Bail if multi-language options not present
		if ( document.querySelector( _mlOptionsWrapper ) == null ) { return; }

		// Set captured click event listener
		document.addEventListener( 'click', handleCapturedClick, true );
	};



	// Run initialize on pageload
	window.addEventListener( 'DOMContentLoaded', init );

})();
