/**
 * File share-icons.js.
 *
 * Enable share buttons functionality.
 *
 * Author: Diego Versiani
 * Contact: https://diegoversiani.me/
 */

(function(){
  
  'use strict';

  var _settings = {
    shareButtonSelector: '.share-button-main',
    shareButtonInternalSelector: '.share-buttons__inside',
    activeClass: 'active',
  }



  /**
   * Handle document clicks and route to the appropriate function.
   */
  var handleCapturedClick = function ( e ) {
    var buttonsPopout = document.querySelector( _settings.shareButtonInternalSelector );
    
    // Bail if button element not present
    if ( ! buttonsPopout ) { return; }
    
    if ( e.target.closest( _settings.shareButtonSelector ) ) {
      e.preventDefault();
      buttonsPopout.classList.toggle( _settings.activeClass );
    }
    else {
      buttonsPopout.classList.remove( _settings.activeClass );
    }
  };



  /**
   * Initialize share button script
   */
  var init = function() {
    var button = document.querySelector( _settings.shareButtonSelector );

    // Bail if share button not available
    if ( ! button ) { return; }

    // Set event listeners
    document.addEventListener( 'click', handleCapturedClick );
  };


  window.addEventListener( 'DOMContentLoaded', init );

})();