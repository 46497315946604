/**
 * File back-to-top.js.
 * Show the floating link when scroll down, and hide when at the top.
 *
 * DEPENDS ON:
 * - shared/throttle.js
 */
(function(){

  'use strict';
  
  var _linkElement;
  var _settings = {
    linkSelector: '[data-scroll-top]',
    linkShowState: 'scroll-button--show',
  };


  //
  // METHODS
  //



  /**
   * Handle document clicks and route to the appropriate function.
   */
  var maybeChangeVisibility = function () {
    var currentScrollPosition = window.pageYOffset || document.body.scrollTop;
    var windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    var scrollPercent = currentScrollPosition / windowHeight;
    var scrollPercentRounded = Math.round( scrollPercent * 100 );

    if ( scrollPercentRounded > 75 ) {
      _linkElement.classList.add( _settings.linkShowState );
    }
    else{
      _linkElement.classList.remove( _settings.linkShowState );
    }
  };



  /**
   * Handle document clicks and route to the appropriate function.
   */
  var handleCapturedClick = function ( e ) {
    if ( e.target.closest( _settings.linkSelector ) ) {
      e.preventDefault();

      window.scrollTo( {
        top: 0,
        behavior: 'smooth'
      } );

      // Remove show state
      _linkElement.classList.remove( _settings.linkShowState );
    }
  };



  /**
   * Loop function to changes visibility of the variation switcher.
   */
  var loop = function() {
    maybeChangeVisibility();

    // Loop this function indefinitely
    window.requestAnimationFrame( loop );
  };



  /**
   * Initialize component and set related handlers
   */
  var init = function() {
    _linkElement = document.querySelector( _settings.linkSelector );

    // Bail early if component not loaded
    if ( !_linkElement ) { return; }

    // Set event handlers
    document.addEventListener( 'click', handleCapturedClick );

    loop();
  };



  // Run initialize on pageload
  window.addEventListener( 'load', init );

})();
