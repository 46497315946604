/**
 * File modals-init.js.
 *
 * Initialize modal windows.
 *
 * Author: Diego Versiani
 * Contact: https://diegoversiani.me/
 */

(function(){

	'use strict';

	var _modalsSettings;
	var _settings = {
		modalToggleClassSelector: '.js-modal-toggle',
		modalWindowClassPrefix: 'js-modal-window-',
	};



	/**
	 * Stop YouTube, Vimeo, and HTML5 videos from playing when leaving the slide
	 */
	var stopVideos = function ( content, settings ) {

		// Check if stop video enabled
		if ( !settings.stopVideo ) return;

		// Check if the video is an iframe or HTML5 video
		var iframe = content.querySelector( 'iframe');
		var video = content.querySelector( 'video' );

		// Stop the video
		if ( iframe ) {
			var iframeSrc = iframe.src.replace( 'autoplay=1', 'autoplay=0' );
			iframe.src = iframeSrc;
		}
		if ( video ) {
			video.pause();
		}
	};



	/**
	 * Open modal window based on css class names instead of attributes
	 */
	var openModalFromClasses = function( toggleElement ) {
		// Iterate classes
		for ( var i = 0; i < toggleElement.classList.length; i++ ) {
			var cssClass = toggleElement.classList[i];
			if ( cssClass.indexOf( _settings.modalWindowClassPrefix ) == 0 ) {
				// Get modal id from class name
				var modalId = '#' + cssClass.replace( _settings.modalWindowClassPrefix, '' );
				window.modals.openModal( null, modalId );
			}
		}
	}



	/**
	 * Run after a modal window opens
	 */
	var openModalCallback = function( toggle, modal ) {
		// Fix: Needed to fix caret in form fields inside modal offset on iOS
		document.body.classList.add( 'modal-open' );
	};



	/**
	 * Run after a modal window closes
	 */
	var closeModalCallback = function( toggle, modal ) {
		// Fix: Caret in form fields inside modal offset on iOS
		document.body.classList.remove( 'modal-open' );

		// Fix: Library stopVideo function does not work with youtube autoplay
		stopVideos( modal, _modalsSettings );
	};



	/**
	 * Handle document clicks and route to the appropriate function.
	 */
	var handleCapturedClick = function ( e ) {
		// MODAL TOGGLE WITH JS CLASSES
		if ( e.target.closest( _settings.modalToggleClassSelector ) ) {
			e.preventDefault();
			var toggleElement = e.target.closest( _settings.modalToggleClassSelector );
			openModalFromClasses( toggleElement );
		}
	};



	/**
	 * Initialize component and set related handlers
	 */
	var init = function() {
		// Bail early if component not loaded
		if ( ! window.modals ) { return; }

		// Initialize modal components with custom settings
		_modalsSettings = {
			selectorWindow: 'div.play, [data-modal-window]',
			backspaceClose: false,
			stopVideo: true,
			callbackOpen: openModalCallback,
			callbackClose: closeModalCallback
		};
		window.modals.init( _modalsSettings );

		// Add custom event listeners
		window.addEventListener( 'click', handleCapturedClick );
	};



	// Run initialize on pageload
	window.addEventListener( 'load', init );

	
})();
